import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { centerColumn } from 'utils/commonStyles';
import { useStore } from 'stores/store';
import SessionForm from 'components/Session/Conference/SessionForm';
import { observer } from 'mobx-react-lite';
import LoadingComponent from 'components/Shared/LoadingComponent';

const Create = () => {
  const { annualConferenceStore } = useStore();

  useEffect(() => {
    annualConferenceStore.getMeetingYear(30042);
  }, [annualConferenceStore]);

  if (annualConferenceStore.conferenceLoading || !annualConferenceStore.conferenceMeeting) return <LoadingComponent />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
      }}
    >
      <Box sx={{ ...centerColumn, rowGap: '1em' }}>
        <Typography textAlign={'center'} variant="h2">
          Create Session
        </Typography>
        <Box
          sx={{
            ...centerColumn,
            rowGap: '.5em',
            width: '100%',
            maxWidth: '80vw',
          }}
        >
          <SessionForm />
        </Box>
      </Box>
    </Box>
  );
};

export default observer(Create);
