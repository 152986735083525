import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import { Category, MeetingRoom, ProgramArea, ProgramLevel, Sponsor, Topic, Venue } from '../utils/types/entity';
import { TypeMix } from '../utils/types/sessionTypes';

export default class EntityStore {
  categories: Category[] = [];
  meetingRooms: MeetingRoom[] = [];
  programAreas: ProgramArea[] = [];
  programLevels: ProgramLevel[] = [];
  sponsors: Sponsor[] = [];
  topics: Topic[] = [];
  venues: Venue[] = [];

  entitiesLoading: boolean = true;

  selectedMeetingType: TypeMix | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  selectMeetingType = (type: TypeMix | null): void => {
    this.selectedMeetingType = type;
  };

  getSessionDropdown = async (): Promise<void> => {
    try {
      this.entitiesLoading = true;
      const dropdowns = await agent.Entity.dropdowns();
      runInAction(() => {
        this.categories = dropdowns.ProductCategories;
        this.programAreas = dropdowns.ProgramAreas;
        this.programLevels = dropdowns.ProgramLevels;
        this.topics = dropdowns.MeetingProgramCategories;
        this.venues = dropdowns.Venues;
        this.meetingRooms = dropdowns.MeetingRooms;
      });
    } finally {
      runInAction(() => {
        this.entitiesLoading = false;
      });
    }
  };
}
