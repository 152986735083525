import {
  InputAdornment,
  Box,
  TextField,
  Button,
  FormControl,
  Autocomplete,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  Input,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { centerColumn, stickyButton } from 'utils/commonStyles';
import { useStore } from 'stores/store';
import { observer } from 'mobx-react-lite';
import Persons from 'components/Session/Conference/SessionForm/Person';
import NumberInput from 'components/Shared/inputs/NumberInput';
import LoadingComponent from 'components/Shared/LoadingComponent';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Types } from 'utils/types/sessionTypes';
import { StatusIds } from 'utils/enums/status';
import styles from './SessionForm.module.scss';
import { useEffect, useState } from 'react';
import { SessionViewExModel } from 'utils/types/session';
import { defaultSession } from 'utils/defaults/session';
import agent from 'api/agent';

const SessionForm = observer(() => {
  const { sessionStore, annualConferenceStore, entityStore } = useStore();
  const [session, setSession] = useState<SessionViewExModel>(Object.assign({}, sessionStore.selected));

  useEffect(() => {
    annualConferenceStore.getYears();
    entityStore.getSessionDropdown();
  }, [annualConferenceStore, entityStore]);

  if (sessionStore.sessionsLoading || entityStore.entitiesLoading || annualConferenceStore.conferenceLoading)
    return <LoadingComponent />;

  return (
    <Box sx={{ ...centerColumn, rowGap: '.5em' }}>
      <Button
        onClick={async () => {
          await sessionStore.save(session);
        }}
        variant="contained"
        sx={stickyButton}
      >
        Save
      </Button>
      <TextField
        required
        fullWidth
        value={session.Name}
        onChange={(e) => {
          if (!e.currentTarget) return;
          setSession((x) => ({
            ...x,
            Name: e.currentTarget.value,
          }));
        }}
        label="Title"
        variant="outlined"
      />
      <Box className={styles.container}>
        <Box className={styles.columnContainer}>
          <Box className={styles.column}>
            <Box className={styles.row}>
              <Box className={styles.formWrapper}>
                <FormControl fullWidth>
                  <Autocomplete
                    autoHighlight
                    value={annualConferenceStore.year}
                    onChange={(_, value) => {
                      annualConferenceStore.selectYear(value?.ProductId);
                      setSession((x) => ({
                        ...x,
                        ParentProductId: value?.ProductId,
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Parent Product" />}
                    options={annualConferenceStore.years}
                    getOptionLabel={(option) => option.Name}
                    isOptionEqualToValue={(x, y) => x.ProductId === y.ProductId}
                  />
                </FormControl>
              </Box>
              <Box className={styles.formWrapper}>
                <FormControl fullWidth>
                  <Autocomplete
                    autoHighlight
                    value={session.Category}
                    onChange={(_, value) => {
                      if (!value) return;
                      setSession((x) => ({
                        ...x,
                        Category: value,
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Type" required />}
                    options={entityStore.categories}
                    getOptionLabel={(option) => option.Name}
                    isOptionEqualToValue={(x, y) => x.Id === y.Id}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={styles.row}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker label="Start" value={session.StartDate} />
                <DateTimePicker label="End" value={session.EndDate} />
              </LocalizationProvider>
            </Box>
            <Box className={styles.row}>
              <TextField
                sx={{
                  minWidth: {
                    xs: '100%',
                    md: '15em',
                  },
                }}
                value={session.Code}
                onChange={(e) => {
                  if (!e.currentTarget) return;
                  setSession((x) => ({
                    ...x,
                    Code: e.currentTarget.value,
                  }));
                }}
                label="Code"
                required
                variant="outlined"
              />
            </Box>
            <Box className={styles.row}>
              <Box className={styles.formWrapper}>
                <FormControl fullWidth>
                  <Autocomplete
                    autoHighlight
                    value={
                      session.ProgramArea || {
                        Id: 8,
                        Name: 'NA',
                      }
                    }
                    onChange={(_, value) => {
                      setSession((x) => ({
                        ...x,
                        ProgramArea: value || {
                          Id: 8,
                          Name: 'NA',
                        },
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Category" required />}
                    options={entityStore.programAreas}
                    getOptionLabel={(option) => option.Name}
                    isOptionEqualToValue={(x, y) => x.Id === y.Id}
                  />
                </FormControl>
              </Box>
              <Box className={styles.formWrapper}>
                <FormControl fullWidth>
                  <Autocomplete
                    autoSelect
                    autoHighlight
                    value={session?.ProgramLevel}
                    onChange={(_, value) => {
                      setSession((x) => ({
                        ...x,
                        ProgramLevel: value,
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Target Audience" />}
                    options={entityStore.programLevels}
                    getOptionLabel={(option) => option.Name}
                    isOptionEqualToValue={(x, y) => x.Id === y.Id}
                  />
                </FormControl>
              </Box>
              <Box className={styles.formWrapper}>
                <FormControl fullWidth>
                  <Autocomplete
                    autoHighlight
                    value={
                      entityStore.topics.find((x) => x.Id === session.MeetingProgramCategoryId) || {
                        Id: session.MeetingProgramCategoryId,
                        Name: session.MeetingProgramCategoryName,
                      }
                    }
                    onChange={(_, value) => {
                      if (!value) return;
                      setSession((x) => ({
                        ...x,
                        MeetingProgramCategoryId: value.Id,
                        MeetingProgramCategoryName: value.Name,
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Program Topic" required />}
                    options={entityStore.topics}
                    getOptionLabel={(option) => option.Name}
                    isOptionEqualToValue={(x, y) => x.Id === y.Id && x.Name === y.Name}
                  />
                </FormControl>
              </Box>
              <Box className={styles.formWrapper}>
                <FormControl fullWidth>
                  <Autocomplete
                    autoHighlight
                    value={entityStore.selectedMeetingType}
                    onChange={(_, v) => {
                      entityStore.selectMeetingType(v);
                      setSession((x) => ({
                        ...x,
                        Type: !v
                          ? null
                          : {
                              Id: v.TypeId,
                              Name: v.Name,
                              Description: '',
                            },
                        ProgramType: !v
                          ? null
                          : {
                              Id: v.ProgramTypeId,
                              Name: v.Name,
                              Description: '',
                            },
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Meeting Type" />}
                    options={Types}
                    getOptionLabel={(option) => option.Name}
                    isOptionEqualToValue={(x, y) => x.TypeId === y.TypeId && x.ProgramTypeId === y.ProgramTypeId}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={styles.row}>
              <Box className={styles.formWrapper}>
                <FormControl fullWidth>
                  <Autocomplete
                    autoHighlight
                    value={session.Venue}
                    onChange={(_, value) => {
                      if (!value) return;
                      setSession((x) => ({
                        ...x,
                        Venue: value,
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Venue" />}
                    options={entityStore.venues}
                    getOptionLabel={(option) => option.Name}
                    isOptionEqualToValue={(x, y) => x.Id === y.Id}
                  />
                </FormControl>
              </Box>
              <Box className={styles.formWrapper}>
                <FormControl fullWidth>
                  <Autocomplete
                    autoHighlight
                    value={session.MeetingRoom}
                    onChange={(_, value) => {
                      if (!value) return;

                      setSession((x) => ({
                        ...x,
                        MeetingRoom: value,
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Meeting Room" />}
                    options={entityStore.meetingRooms}
                    getOptionLabel={(option) => option.Name}
                    isOptionEqualToValue={(x, y) => x.Id === y.Id}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={styles.row}>
              <Box className={styles.formWrapper}>
                <FormControl
                  sx={{
                    minWidth: 'fit-content',
                  }}
                >
                  <FormLabel>Is Medical CE?</FormLabel>
                  <RadioGroup
                    row
                    onChange={(e) => {
                      if (!e) return;
                      setSession((x) => ({
                        ...x,
                        Credits: [
                          {
                            ...(x.Credits.at(0) || defaultSession.Credits[0]),
                            IsMedical: JSON.parse(e.target.value.toLowerCase()),
                          },
                        ],
                      }));
                    }}
                    value={session.Credits.at(0)?.IsMedical}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box className={styles.formWrapper}>
                <NumberInput
                  label="CE Credits"
                  value={session.Credits[0].EducationUnits}
                  onChange={(value) => {
                    setSession((x) => ({
                      ...x,
                      Credits: [
                        {
                          ...session.Credits[0],
                          EducationUnits: value,
                        },
                      ],
                    }));
                  }}
                />
              </Box>
              <Box className={styles.formWrapper}>
                <NumberInput
                  label="Price"
                  isMoney
                  onChange={(value) => {
                    setSession((x) => ({
                      ...x,
                      Price: [
                        {
                          ...session.Price[0],
                          Price: value,
                        },
                      ],
                    }));
                  }}
                  value={session.Price[0]?.Price || 0}
                />
              </Box>
              <Box className={styles.formWrapper}>
                <NumberInput
                  label="Max Registrants"
                  onChange={(value) => {
                    setSession((x) => ({
                      ...x,
                      MaxRegistrants: value,
                    }));
                  }}
                  value={session.MaxRegistrants || 0}
                />
              </Box>
            </Box>
          </Box>
          <Box className={styles.column}>
            <TextField
              multiline
              fullWidth
              minRows={20}
              value={session.Description}
              onChange={(e) => {
                if (!e) return;
                setSession((x) => ({
                  ...x,
                  Description: e.target.value,
                }));
              }}
              label="Description"
              variant="outlined"
            />
          </Box>
        </Box>
        <Box>
          <Persons />
        </Box>
        <Box>
          <FormControl fullWidth>
            <Autocomplete
              autoSelect
              autoHighlight
              multiple
              value={session.Sponsors}
              onChange={(_, value) => {
                setSession((x) => ({
                  ...x,
                  Sponsors: value,
                }));
              }}
              renderInput={(params) => <TextField {...params} label="Sponsor" />}
              options={entityStore.sponsors}
              getOptionLabel={(option) => option.SponsorName}
            />
          </FormControl>
        </Box>
        <Box>
          <TextField
            fullWidth
            value={session.Keywords}
            onChange={(e) => {
              if (!e.currentTarget.value) return;
              setSession((x) => ({
                ...x,
                Keywords: e.currentTarget.value,
              }));
            }}
            label="Keywords"
            variant="outlined"
          />
        </Box>
        <Box>
          <TextField
            fullWidth
            multiline
            value={session.ExternalNotes}
            onChange={(e) => {
              if (!e.currentTarget.value) return;
              setSession((x) => ({
                ...x,
                ExternalNotes: e.currentTarget.value,
              }));
            }}
            label="Session Accommodations"
            variant="outlined"
          />
        </Box>
        <Box>
          <Button
            onClick={() => {
              sessionStore.addLearningObjective();
            }}
            variant="contained"
          >
            Add Learning Objective
          </Button>
          <Box width="100%">
            {session.LearningObjectives?.map((objective) => (
              <Paper
                key={`${objective.MeetingID} ${objective.Id} ${objective.Objective}`}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '.5em 0',
                  width: '100%',
                }}
              >
                <Input
                  placeholder="Objective"
                  sx={{
                    width: '100%',
                    padding: '.5em',
                  }}
                  value={objective.Objective}
                  onChange={(e) => {
                    if (!e.currentTarget.value) return;
                    objective.Objective = e.currentTarget.value;
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setSession((x) => ({
                            ...x,
                            LearningObjectives: session.LearningObjectives.filter(
                              (x) => x.Id !== objective.Id && x.Objective !== objective.Objective
                            ),
                          }));
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Paper>
            ))}
          </Box>
        </Box>
        <Box>
          <TextField
            multiline
            rows={8}
            fullWidth
            value={session.InternalNotes}
            onChange={(e) => {
              if (!e) return;
              setSession((x) => ({
                ...x,
                InternalNotes: e.target.value,
              }));
            }}
            label="Internal Notes"
            variant="outlined"
          />
        </Box>
      </Box>
      {session.ProductId !== undefined && session.ProductId > 0 && session.Status.Id === StatusIds.Planning && (
        <>
          <Button
            onClick={async () => {
              if (!session.ProductId) return;
              await agent.Sessions.submitForApproval(session.ProductId);
            }}
            variant="contained"
          >
            Submit
          </Button>
          <Button
            onClick={async () => {
              // TODO: Need the requirments for who can cancel a meeting
              if (!session.ProductId) return;
            }}
            variant="contained"
            color="error"
          >
            Cancel Session
          </Button>
        </>
      )}
      {session.ProductId !== undefined && session.ProductId > 0 && (
        <Button
          onClick={async () => {
            if (!session.ProductId) return;
            sessionStore.duplicateSelectedSession();
          }}
          variant="contained"
          color="secondary"
        >
          Duplicate Session
        </Button>
      )}
    </Box>
  );
});

export default SessionForm;
