import { Roles } from './enums/roles';

interface LinkDisplay {
  header: string;
  links: Link[];
}

interface Link {
  url: string;
  display: string;
  roles: Roles[];
}

export const links: LinkDisplay[] = [
  {
    header: 'Session',
    links: [
      {
        url: '/session/create',
        display: 'Create New Session',
        roles: [Roles.Education, Roles.ITUser],
      },
      { url: '/session/search', display: 'Search Sessions', roles: [Roles.Education, Roles.ITUser] },
      {
        url: `/session/approval`,
        display: 'Review Sessions',
        roles: [Roles.EducationHead, Roles.Accounting, Roles.ITUser],
      },
    ],
  },
  {
    header: 'Inventory',
    links: [
      {
        url: '/inventory/search',
        display: 'Search Inventory',
        roles: [Roles.Education, Roles.ITUser],
      },
      {
        url: '/inventory/create',
        display: 'Create Resource',
        roles: [Roles.EducationHead, Roles.Accounting, Roles.ITUser],
      },
    ],
  },
];
