import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { makeAutoObservable } from 'mobx';
import { store } from './store';

dayjs.extend(utc);

export default class TimeStore {
  startTime: Dayjs = dayjs().utc(true).hour(6).minute(0).second(0).millisecond(0);
  endTime: Dayjs = dayjs().utc(true).hour(6).minute(30).second(0).millisecond(0);

  constructor() {
    makeAutoObservable(this);
  }

  setStartTime = (day: Dayjs | null) => {
    if (!day || !store.annualConferenceStore.date) return;
    day.date(store.annualConferenceStore.date.getUTCDate());
    day.month(store.annualConferenceStore.date.getUTCMonth());
    day.year(store.annualConferenceStore.date.getUTCFullYear());
    if (day >= this.endTime) {
      this.startTime = day;
      this.endTime = day.minute(day.utc().minute() + 30);
      return;
    }
    this.startTime = day;
  };

  setEndTime = (day: Dayjs | null) => {
    if (!day || !store.annualConferenceStore.date) return;
    day.date(store.annualConferenceStore.date.getUTCDate());
    day.month(store.annualConferenceStore.date.getUTCMonth());
    day.year(store.annualConferenceStore.date.getUTCFullYear());
    if (day <= this.startTime) {
      this.startTime = day.minute(day.utc().minute() - 30);
      this.endTime = day;
      return;
    }
    this.endTime = day;
  };
}
