import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';
import agent from 'api/agent';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Resource } from 'utils/types/resources';
import styles from './Search.module.scss';

const Search: React.FunctionComponent = () => {
  const [resources, setResources] = useState<Resource[]>([]);

  useEffect(() => {
    agent.Inventory.list().then((resp) => {
      setResources(resp);
    });
  }, []);

  return (
    <Box className={styles.container}>
      {resources.map((r) => (
        <Card key={r.Id} className={styles.card}>
          <CardHeader title={r.Name} />
          <CardContent>
            <Typography>{r.Description}</Typography>
            <Typography>Available: {r.Quantity}</Typography>
          </CardContent>
          <CardActions>
            <Link to={`/inventory/edit/${r.Id}`}>
              <Button variant="contained">Edit</Button>
            </Link>
          </CardActions>
        </Card>
      ))}
    </Box>
  );
};

export default Search;
