import { makeAutoObservable, reaction } from 'mobx';
import { ServerError } from '../utils/types/serverError';

export default class CommonStore {
  error: ServerError | null = null;
  accessToken: string | null = window.localStorage.getItem('access_token');
  idToken: string | null = window.localStorage.getItem('id_token');
  refreshToken: string | null = window.localStorage.getItem('refresh_token');
  appLoaded: boolean = false;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.refreshToken,
      (token) => {
        if (token) {
          window.localStorage.setItem('refresh_token', token);
        } else {
          window.localStorage.removeItem('refresh_token');
        }
      }
    );

    reaction(
      () => this.idToken,
      (token) => {
        if (token) {
          window.localStorage.setItem('id_token', token);
        } else {
          window.localStorage.removeItem('id_token');
        }
      }
    );

    reaction(
      () => this.accessToken,
      (token) => {
        if (token) {
          window.localStorage.setItem('access_token', token);
        } else {
          window.localStorage.removeItem('access_token');
        }
      }
    );
  }

  setServerError = (error: ServerError) => {
    this.error = error;
  };

  setTokens = (accessToken: string | null, idToken: string | null, refreshToken: string | null) => {
    this.accessToken = accessToken;
    this.idToken = idToken;
    this.refreshToken = refreshToken;
  };

  setAppLoaded = () => {
    this.appLoaded = true;
  };
}
