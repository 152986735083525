import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

interface PropTypes {
  label: string;
  value?: number;
  isMoney?: boolean;
  onChange?: (n: number) => void;
}

const NumberInput = ({ label, value, isMoney, onChange = () => {} }: PropTypes) => {
  return (
    <TextField
      fullWidth
      type="number"
      value={value}
      label={label}
      onChange={(e) => {
        onChange(parseInt(e.currentTarget.value));
      }}
      InputProps={{
        startAdornment: isMoney ? <InputAdornment position="start">$</InputAdornment> : null,
      }}
    />
  );
};

export default NumberInput;
