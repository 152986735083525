import { Navigate, RouteObject } from 'react-router-dom';
import App from 'components/App';
import PrivateOutlet from 'components/Shared/PrivateOutlet';
import SessionCreate from 'components/Session/Conference/Create';
import SessionEdit from 'components/Session/Conference/Edit';
import SessionApproval from 'components/Session/Approval';
import SessionReview from 'components/Session/Review';
import InventoryCreate from 'components/Inventory/Edit';
import InventoryEdit from 'components/Inventory/Edit';
import InventorySearch from 'components/Inventory/Search';
import Home from 'components/Home';
import Login from 'components/Login';
import NotFound from 'components/NotFound';
import { Roles } from 'utils/enums/roles';
import Academy from 'components/Session/AllSearch';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <PrivateOutlet />,
        children: [
          {
            path: '',
            element: <Home />,
          },
        ],
      },
      {
        path: 'session',
        element: <PrivateOutlet roles={[Roles.Education, Roles.ITUser]} />,
        children: [
          {
            path: 'create',
            element: <SessionCreate />,
          },
          {
            path: 'edit/:productId',
            element: <SessionEdit />,
          },
          {
            path: 'search',
            element: <Academy />,
          },
        ],
      },
      {
        path: 'session',
        element: <PrivateOutlet roles={[Roles.EducationHead, Roles.Accounting, Roles.ITUser]} />,
        children: [
          {
            path: 'approval',
            element: <SessionApproval />,
          },
          {
            path: 'review/:productId',
            element: <SessionReview />,
          },
        ],
      },
      {
        path: 'inventory',
        element: <PrivateOutlet />,
        children: [
          {
            path: 'search',
            element: <InventorySearch />,
          },
          {
            path: 'edit/:resourceId',
            element: <InventoryEdit />,
          },
          {
            path: 'create',
            element: <InventoryCreate />,
          },
        ],
      },
      { path: 'login', element: <Login /> },
      { path: 'not-found', element: <NotFound /> },
      { path: '*', element: <Navigate replace to="/not-found" /> },
    ],
  },
];
