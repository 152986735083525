import { Button, List, ListItem, ListItemText, ListSubheader, Typography } from '@mui/material';
import { Box } from '@mui/system';
import agent from 'api/agent';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from 'stores/store';
import { getFormattedDateCombination } from 'utils/date';
import { StatusIds } from 'utils/enums/status';
import styles from './Review.module.scss';

const Review: React.FunctionComponent = () => {
  const { productId } = useParams();
  const { sessionStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!productId) return;

    const id = parseInt(productId);
    sessionStore.getSingle(id);
  }, [productId, sessionStore]);

  const onDecision = (accepted: boolean) => {
    if (!productId) return;

    const currentStatusId = sessionStore.selected.Status.Id;

    if (currentStatusId === StatusIds.EducationSubmitted) {
      agent.Sessions.headOfEduDecision(parseInt(productId), accepted);
    } else if (currentStatusId === StatusIds.HeadOfEduApproved) {
      agent.Sessions.accountingDecision(parseInt(productId), accepted);
    }

    navigate('/approval');
  };

  return (
    <Box className={styles.root}>
      <Typography variant="h3">{sessionStore.selected.Name}</Typography>
      <Typography variant="h5">
        {sessionStore.selected.Code} -{' '}
        {getFormattedDateCombination(sessionStore.selected.StartDate.toDate(), sessionStore.selected.EndDate.toDate())}
      </Typography>
      <List dense>
        <ListItem>
          <ListItemText primary={`Description: ${sessionStore.selected.Description}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Max Registrations: ${sessionStore.selected.MaxRegistrants}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Meeting Category: ${sessionStore.selected.Category?.Name ?? ''}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Parent Product Id: ${sessionStore.selected.ParentProductId}`} />
        </ListItem>
      </List>
      <List dense>
        <ListSubheader>Categorization</ListSubheader>
        <ListItem>
          <ListItemText primary={`Area: ${sessionStore.selected.ProgramArea?.Name ?? ''}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Type: ${sessionStore.selected.ProgramType?.Name ?? ''}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Level: ${sessionStore.selected.ProgramLevel?.Name ?? ''}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Category: ${sessionStore.selected.MeetingProgramCategoryName}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Keyword(s): ${sessionStore.selected.Keywords}`} />
        </ListItem>
      </List>
      <List dense>
        <ListSubheader>Location</ListSubheader>
        <ListItem>
          <ListItemText primary={`Venue: ${sessionStore.selected.Venue?.Name ?? ''}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Room: ${sessionStore.selected.MeetingRoom?.Name ?? ''}`} />
        </ListItem>
      </List>
      <List>
        <ListSubheader>Target Audiences</ListSubheader>
        {sessionStore.selected.TargetAudience.map((s) => (
          <ListItem key={s.Id}>
            <ListItemText primary={s.RegistrantType} />
          </ListItem>
        ))}
      </List>
      <List>
        <ListSubheader>Sponsors</ListSubheader>
        {sessionStore.selected.Sponsors.map((s) => (
          <ListItem key={s.Id}>
            <ListItemText primary={s.SponsorName} />
          </ListItem>
        ))}
      </List>
      <List component={'ol'}>
        <ListSubheader>Learning Objectives</ListSubheader>
        {sessionStore.selected.LearningObjectives.map((s) => (
          <ListItem key={s.Id}>
            <ListItemText primary={s.Objective} />
          </ListItem>
        ))}
      </List>
      <List component="ol">
        <ListSubheader>Speakers</ListSubheader>
        {sessionStore.selected.Speakers.map((s) => (
          <ListItem key={s.Id}>
            <ListItemText primary={`${s.FirstName} ${s.LastName}`} secondary={s.Type} />
          </ListItem>
        ))}
      </List>
      <List>
        <ListSubheader>Credits</ListSubheader>
        {sessionStore.selected.Credits.map((c) => (
          <ListItem key={c.Id}>
            <ListItemText primary={`${c.EducationUnits} CE`} secondary={c.IsMedical ? 'Medical' : 'Non-Medical'} />
          </ListItem>
        ))}
      </List>
      <List>
        <ListSubheader>Prices</ListSubheader>
        {sessionStore.selected.Price.map((p) => (
          <ListItem key={p.Id}>
            <ListItemText primary={`Amount: $${p.Price}`} />
          </ListItem>
        ))}
      </List>
      <Box className={styles.buttonBox}>
        <Button
          className={styles.button}
          color="error"
          variant="contained"
          onClick={() => {
            onDecision(false);
          }}
        >
          deny
        </Button>
        <Button
          className={styles.button}
          color="primary"
          variant="contained"
          onClick={() => {
            onDecision(true);
          }}
        >
          accept
        </Button>
      </Box>
    </Box>
  );
};

export default observer(Review);
