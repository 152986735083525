import { SxProps } from '@mui/material';

export const centerColumn: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  margin: 0,
  padding: 0,
};

export const stickyButton: SxProps = {
  position: 'fixed',
  top: {
    xs: '5em',
    md: '5em',
  },
  marginRight: {
    xs: '-2em',
    md: '-6em',
  },
  alignSelf: 'flex-end',
  zIndex: 100,
};

export const inputRow: SxProps = {
  display: 'flex',
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  columnGap: '.25em',
  rowGap: '.5em',
  alignItems: 'center',
  width: '100%',
};
