export enum StatusIds {
  Planned = 1,
  Occured,
  Cancelled,
  EducationSubmitted,
  HeadOfEduApproved,
  Planning,
}

export const StatusIdNames = [
  { id: StatusIds.Planned, text: 'Planned' },
  { id: StatusIds.Occured, text: 'Occured' },
  { id: StatusIds.Cancelled, text: 'Cancelled' },
  { id: StatusIds.EducationSubmitted, text: 'Education Submitted' },
  { id: StatusIds.HeadOfEduApproved, text: 'Head of Education Approved' },
  { id: StatusIds.Planning, text: 'Planning' },
];
