import { makeAutoObservable } from 'mobx';

import { Person } from '../utils/types/person';

export default class PersonStore {
  persons: Person[] = [];

  constructor() {
    makeAutoObservable(this);
    this.getPeople();
  }

  setBookedTime = () => {
    try {
      // TODO: Send endpoint request to book a time for selected moderator
      // in the selected time
    } catch (e) {
      console.error(e);
    }
  };

  private getPeople = () => {
    try {
      // TODO: Grab all speakers and co-speakers from endpoint
    } catch (e) {
      console.error(e);
    }
  };
}
