import { createContext, useContext } from 'react';

import AnnualConferenceStore from './annualConferenceStore';
import CommonStore from './commonStore';
import EntityStore from './entityStore';
import PersonStore from './personStore';
import SessionStore from './sessionStore';
import TimeStore from './timeStore';
import UserStore from './userStore';

interface Store {
  userStore: UserStore;
  commonStore: CommonStore;
  sessionStore: SessionStore;
  annualConferenceStore: AnnualConferenceStore;
  personStore: PersonStore;
  entityStore: EntityStore;
  timeStore: TimeStore;
}

export const store: Store = {
  userStore: new UserStore(),
  commonStore: new CommonStore(),
  sessionStore: new SessionStore(),
  annualConferenceStore: new AnnualConferenceStore(),
  personStore: new PersonStore(),
  entityStore: new EntityStore(),
  timeStore: new TimeStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
