import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useStore } from 'stores/store';
import LoadingComponent from 'components/Shared/LoadingComponent';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/material';
import { theme } from 'utils/theme';

function App() {
  const { userStore, commonStore } = useStore();

  useEffect(() => {
    if (commonStore.accessToken) {
      userStore
        .getUser()
        .catch(() => userStore.logout())
        .finally(() => {
          commonStore.setAppLoaded();
        });
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore]);

  if (!commonStore.appLoaded) return <LoadingComponent />;

  return (
    <>
      <ToastContainer position="bottom-right" hideProgressBar />
      <ThemeProvider theme={theme}>
        test
        <Outlet />
      </ThemeProvider>
    </>
  );
}

export default observer(App);
