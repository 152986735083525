import React from 'react';
import { Link, Toolbar, Box, AppBar, Typography, Menu, MenuItem } from '@mui/material';
import { useStore } from 'stores/store';
import { observer } from 'mobx-react-lite';
import { useMsal } from '@azure/msal-react';
import { containsRoles } from 'utils/roles';
import { links } from 'utils/links';

const NavBar = () => {
  const msal = useMsal();
  const {
    userStore: { user },
  } = useStore();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const linksToDisplay = [
    <Link color="inherit" href="/" key={'home'}>
      Home
    </Link>,
    links.map((ld) =>
      ld.links.map((l) => (
        <Link
          key={l.display}
          color="inherit"
          hidden={!containsRoles({ account: msal.accounts, roles: l.roles })}
          href={l.url}
        >
          {l.display}
        </Link>
      ))
    ),
    <Typography
      color="primary.main"
      key={'name'}
      sx={{
        color: 'inherit',
        marginTop: { xs: '.5em', md: '0' },
        marginLeft: { xs: '0', md: 'auto' },
      }}
    >
      {user?.client_info}
    </Typography>,
    <Link
      key={'logout'}
      sx={{ cursor: 'pointer', color: 'inherit' }}
      onClick={(_) => {
        msal.instance.logoutRedirect({
          postLogoutRedirectUri: '/login',
        });
      }}
    >
      Logout
    </Link>,
  ];

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      sx={{
        flexGrow: 1,
        backgroundColor: 'secondary.main',
      }}
    >
      <Toolbar sx={{ display: 'flex', maxWidth: '100vw' }}>
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            marginLeft: '1em',
            width: '100%',
            columnGap: '1em',
          }}
        >
          {linksToDisplay}
        </Box>
        <Box
          sx={{
            flexDirection: 'row-reverse',
            display: { xs: 'flex', md: 'none' },
          }}
        >
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {linksToDisplay.map((el, i) => (
              <MenuItem color="white" key={i}>
                {el}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default observer(NavBar);
