import { Navigate, Outlet } from 'react-router';
import { observer } from 'mobx-react-lite';
import Layout from 'components/Shared/Layout';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

interface PropTypes {
  roles?: string[];
}

const PrivateOutlet = ({ roles }: PropTypes) => {
  const isAuthenticated = useIsAuthenticated();
  const msal = useMsal();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (roles && !roles.some((role) => msal.accounts.find((x) => x.idTokenClaims?.roles?.includes(role)))) {
    return <Navigate to="/" />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default observer(PrivateOutlet);
