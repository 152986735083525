import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routes } from 'components/Routes';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from 'api/sso';

export const msalInstance = new PublicClientApplication(msalConfig);

export const router = createBrowserRouter(routes);

ReactDOM.createRoot(
  (document.getElementById('root') as HTMLElement) || (document.createElement('div') as HTMLElement)
).render(
  <MsalProvider instance={msalInstance}>
    <RouterProvider router={router} />
  </MsalProvider>
);
