import dayjs from 'dayjs';
import { makeAutoObservable, reaction, runInAction } from 'mobx';

import agent from '../api/agent';
import { dateFormatObject } from '../utils/date';
import { AnnualConference } from '../utils/types/annualConference';
import { Meeting } from '../utils/types/meeting';

import { store } from './store';

export default class AnnualConferenceStore {
  dates: Date[] = [];
  conferenceMeeting: Meeting | null = null;
  years: AnnualConference[] = [];
  year: AnnualConference | undefined;
  date: Date = this.dates[0];

  conferenceLoading: boolean = true;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.date,
      (x) => {
        store.timeStore.setStartTime(dayjs.utc(x).hour(6).minute(0).second(0));
        store.timeStore.setEndTime(dayjs.utc(x).hour(6).minute(30).second(0));
      }
    );
  }

  selectYear = (id?: number): void => {
    this.year = this.years.find((year) => year.ProductId === id);
  };

  selectDate = (date: Date): void => {
    this.date =
      this.dates.find((d) => {
        return date.toLocaleDateString('en-US', dateFormatObject) === d.toLocaleDateString('en-US', dateFormatObject);
      }) || this.dates[0];
  };

  getYears = (): void => {
    agent.AnnualConferenceNames.list().then((x) => {
      runInAction(() => {
        this.years = x;
      });
    });
  };

  getMeetingYear = (productId: number): void => {
    this.conferenceLoading = true;
    agent.AnnualConferenceNames.getSingle(productId)
      .then((x) => {
        const startDate = new Date(x.StartDate);
        startDate.setDate(startDate.getDate() - 1);
        const conferenceMeeting = {
          ...x,
          StartDate: startDate,
          EndDate: new Date(x.EndDate),
        };

        runInAction(() => {
          this.conferenceMeeting = conferenceMeeting;
          this.getDates();

          this.date = new Date(startDate);
        });
        store.timeStore.setStartTime(dayjs.utc(startDate).hour(6).minute(0).second(0));
        store.timeStore.setEndTime(dayjs.utc(startDate).hour(6).minute(30).second(0));
      })
      .finally(() => {
        runInAction(() => {
          this.conferenceLoading = false;
        });
      });
  };

  private getDates = (): void => {
    if (!this.conferenceMeeting) return;
    this.dates = [];
    let currentDate: Date = this.conferenceMeeting.StartDate;

    this.date = currentDate;
    while (currentDate <= this.conferenceMeeting.EndDate) {
      this.dates.push(currentDate);
      currentDate = this.addOne(currentDate);
    }
  };

  private addOne = (toDate: Date): Date => {
    const result = new Date(toDate);
    result.setDate(result.getDate() + 1);
    return result;
  };
}
