import NavMenu from 'components/Shared/NavMenu';
import { Box, Toolbar } from '@mui/material';

interface PropTypes {
  children?: JSX.Element;
}

const Layout = ({ children }: PropTypes) => {
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Box component={'nav'}>
        <NavMenu />
      </Box>
      <Box
        component={'main'}
        sx={{
          paddingTop: '1.5em',
          width: '100%',
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};
export default Layout;
