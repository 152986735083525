import { observer } from 'mobx-react';
import { StatusIds } from 'utils/enums/status';
import { useEffect, useState } from 'react';
import { useStore } from 'stores/store';
import { Roles } from 'utils/enums/roles';
import { SessionSearchRequest } from 'utils/types/session';
import styles from './Approval.module.scss';
import { Box, TextField } from '@mui/material';
import ProductSearch from '../Shared/ProductSearch';
import { useMsal } from '@azure/msal-react';

const Approval: React.FunctionComponent = () => {
  const { sessionStore } = useStore();
  const msal = useMsal();
  const [searchValue, setSearchValue] = useState<SessionSearchRequest | null>(null);
  const [page, setPage] = useState<number>(1);
  const [statusId, setStatusId] = useState<number[]>([]);

  function handleDelayedSearchChange(key: keyof SessionSearchRequest, searchRequest: SessionSearchRequest): void {
    setSearchValue({ ...searchValue, ...{ [key]: searchRequest[key] }, StatusIds: statusId });
  }

  function handleSearchChange(key: keyof SessionSearchRequest, searchRequest: SessionSearchRequest): void {
    sessionStore.search.setSearchRequest({
      ...searchValue,
      ...{ [key]: searchRequest[key] },
      StatusIds: statusId,
    });
  }

  useEffect(() => {
    const request: SessionSearchRequest = {
      StatusIds: [],
    };
    const roles = msal.accounts.flatMap((a) => a.idTokenClaims?.roles);
    roles.forEach((r) => {
      if (r === Roles.EducationHead || r === Roles.ITUser) {
        request.StatusIds?.push(StatusIds.EducationSubmitted);
      }
      if (r === Roles.Accounting || r === Roles.ITUser) {
        request.StatusIds?.push(StatusIds.HeadOfEduApproved);
      }
    });
    setStatusId(request.StatusIds ?? []);
    sessionStore.get(request);
  }, [msal.accounts, sessionStore]);

  useEffect(() => {
    if (searchValue !== null) {
      const delayDebounceFn = setTimeout(async () => {
        sessionStore.search.setSearchRequest(searchValue);
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchValue, sessionStore.search]);

  return (
    <Box className={styles.root}>
      <TextField
        fullWidth
        id="outlined-basic"
        label="Search Sessions"
        variant="outlined"
        value={searchValue?.SearchTerm || ''}
        onChange={(e) => {
          handleDelayedSearchChange('SearchTerm', { SearchTerm: e.currentTarget.value });
        }}
      />
      <Box className={styles.sessionContainer}>
        <ProductSearch
          page={page}
          onPageChange={(v) => {
            setPage(v);
            handleSearchChange('Page', { Page: v });
          }}
          buttonText="Review"
          buttonLink="/review"
        />
      </Box>
    </Box>
  );
};

export default observer(Approval);
