import { useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { centerColumn } from 'utils/commonStyles';
import SessionForm from 'components/Session/Conference/SessionForm';
import { useStore } from 'stores/store';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoadingComponent from 'components/Shared/LoadingComponent';

const Edit = () => {
  const { annualConferenceStore, sessionStore } = useStore();
  const { productId } = useParams();

  useEffect(() => {
    if (productId === undefined) return;
    sessionStore.getSingle(parseInt(productId));
  }, [sessionStore, productId, sessionStore.display.length]);

  useEffect(() => {
    if (!sessionStore.selected.ParentProductId) return;
    annualConferenceStore.getMeetingYear(sessionStore.selected.ParentProductId);
  }, [annualConferenceStore, sessionStore.selected]);

  if (sessionStore.singleLoading) return <LoadingComponent />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
      }}
    >
      <Box sx={centerColumn}>
        <Typography textAlign={'center'} variant="h2">
          Edit Session
        </Typography>
        <Box
          sx={{
            ...centerColumn,
            rowGap: '.5em',
            width: '100%',
            maxWidth: '80vw',
          }}
        >
          <SessionForm />
        </Box>
      </Box>
    </Box>
  );
};

export default observer(Edit);
