import dayjs from 'dayjs';

export const dateFormatObject: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  month: 'short',
  day: 'numeric',
};

export const dateSuffix = (d: number) => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const formatTime = (date: Date): string => {
  return date.toLocaleTimeString('en-US', timeFormatObject);
};

const timeFormatObject: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  second: undefined,
};

export const getFormattedDateCombination = (startDate: string | Date, endDate: string | Date): string => {
  const startDateMoment = dayjs(startDate);
  const endDateMoment = dayjs(endDate);

  if (
    startDateMoment.year() === endDateMoment.year() &&
    startDateMoment.month() === endDateMoment.month() &&
    startDateMoment.day() === endDateMoment.day()
  ) {
    return `${startDateMoment.format('LLLL')} to ${endDateMoment.format('LT')}`;
  } else {
    return `${startDateMoment.format('LLLL')} to ${endDateMoment.format('LLLL')}`;
  }
};
