import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, Container } from '@mui/material';
import { loginRequest } from 'api/sso';
import { router } from 'index';
import { observer } from 'mobx-react-lite';

const Login = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogin = () => {
    instance.loginPopup(loginRequest).then(() => {
      router.navigate('/');
    });
  };

  if (isAuthenticated) {
    router.navigate('/');
  }

  return (
    <Container>
      <Button
        onClick={() => {
          handleLogin();
        }}
      >
        Login
      </Button>
    </Container>
  );
};

export default observer(Login);
