import { useMsal } from '@azure/msal-react';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { links } from 'utils/links';
import { containsRoles } from 'utils/roles';
import styles from './Home.module.scss';

const Home = () => {
  const msal = useMsal();
  return (
    <Box sx={{ margin: 'auto', maxWidth: '80vw' }}>
      <Box className={styles.wrapper}>
        {links.map((ld) => (
          <Card key={ld.header}>
            <CardHeader title={ld.header} />
            <CardContent>
              <Box className={styles.linkWrapper}>
                {ld.links.map((l) => (
                  <Link
                    key={l.display}
                    color="inherit"
                    hidden={!containsRoles({ account: msal.accounts, roles: l.roles })}
                    to={l.url}
                  >
                    {l.display}
                  </Link>
                ))}
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default observer(Home);
