import { Box, Button, TextField, Typography } from '@mui/material';
import agent from 'api/agent';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Resource } from 'utils/types/resources';
import styles from './Edit.module.scss';

const Edit: React.FunctionComponent = () => {
  const params = useParams();
  const { reset, handleSubmit, getValues, control } = useForm<Resource>({
    defaultValues: {
      Id: 0,
      Name: '',
      Description: '',
      Quantity: 0,
      TrackingNumber: '',
    },
  });

  useEffect(() => {
    const resourceId = params.resourceId;
    if (resourceId) {
      agent.Inventory.getSingle(parseInt(resourceId)).then((r) => {
        reset(r);
      });
    }
  }, [params, reset]);

  const onSubmit: SubmitHandler<Resource> = () => {
    const r = getValues();
    agent.Inventory.save(r).then((r) => {
      reset(r);
    });
  };

  return (
    <Box className={styles.container}>
      <Typography variant="h2">Edit Resource</Typography>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Controller name="Name" control={control} render={({ field }) => <TextField {...field} label="Name" />} />
        <Controller
          name="Description"
          control={control}
          render={({ field }) => <TextField {...field} label="Description" />}
        />
        <Controller
          name="Quantity"
          control={control}
          render={({ field }) => <TextField {...field} label="Quantity" type="number" />}
        />
        <Controller
          name="TrackingNumber"
          control={control}
          render={({ field }) => <TextField {...field} label="Tracking Number" />}
        />

        <Button type="submit" variant="contained">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default Edit;
