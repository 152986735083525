import { Button, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { observer } from 'mobx-react';
import { useStore } from 'stores/store';
import { mapSpeakerToPerson } from 'utils/person';
import { Person, Speaker } from 'utils/types/person';

interface PropType {
  results: Speaker[];
  setResults: (p: Person[]) => void;
}

const PersonResultsTable = ({ results, setResults }: PropType) => {
  const { sessionStore } = useStore();

  const columns: GridColDef[] = [
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      renderCell: (params) => {
        const row = params.row;
        return (
          <Stack
            sx={{
              padding: 1,
            }}
            direction="column"
            spacing={1}
          >
            {sessionStore.selected.Speakers.map((x) => x.Id).includes(parseInt(row.Id)) ? (
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => {
                  sessionStore.edit({
                    ...sessionStore.selected,
                    Speakers: [...sessionStore.selected.Speakers.filter((x) => x.Id !== row.Id)],
                  });
                  results.push({
                    Id: row.Id,
                    FirstName: row.FirstName,
                    LastName: row.LastName,
                    Type: row.Type || 'Co-Presenter',
                  });
                  setResults(results);
                }}
              >
                Remove
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="success"
                size="small"
                onClick={() => {
                  sessionStore.edit({
                    ...sessionStore.selected,
                    Speakers: [
                      ...sessionStore.selected.Speakers,
                      {
                        Id: row.Id,
                        FirstName: row.FirstName,
                        LastName: row.LastName,
                        Type: row.Type || 'Co-Presenter',
                      },
                    ],
                  });
                  setResults(results.filter((x) => x.Id !== row.Id).map((x) => mapSpeakerToPerson(x)));
                }}
              >
                Add
              </Button>
            )}
          </Stack>
        );
      },
      width: 150,
    },
    {
      field: 'FirstName',
      headerName: 'First name',
      minWidth: 120,
      flex: 1,
    },
    { field: 'LastName', headerName: 'Last name', minWidth: 120, flex: 1 },
    {
      field: 'Type',
      headerName: 'Type',
      align: 'right',
      minWidth: 120,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const row = params.row;
        return sessionStore.selected.Speakers.map((x) => x.Id).includes(row.Id) ? (
          <FormControl fullWidth variant="standard">
            <InputLabel>Type</InputLabel>
            <Select
              value={row.Type}
              label="Type"
              onChange={(e) => {
                row.Type = e.target.value;

                const speaker = sessionStore.selected.Speakers.find((x) => x.Id === row.Id);
                if (!speaker) return;
                sessionStore.edit({
                  ...sessionStore.selected,
                  Speakers: [
                    ...sessionStore.selected.Speakers.filter((x) => x.Id !== speaker.Id),
                    {
                      ...speaker,
                      Type: e.target.value,
                    },
                  ],
                });
              }}
            >
              <MenuItem value="Co-Instructor">Co-Instructor</MenuItem>
              <MenuItem value="Coordinator">Coordinator</MenuItem>
              <MenuItem value="Coordinator and Co-Instructor">Coordinator and Co-Instructor</MenuItem>
              <MenuItem value="Co-Presenter">Co-Presenter</MenuItem>
              <MenuItem value="Co-Presenter and Moderator">Co-Presenter and Moderator</MenuItem>
              <MenuItem value="Instructor">Instructor</MenuItem>
              <MenuItem value="Moderator">Moderator</MenuItem>
              <MenuItem value="Presenter">Presenter</MenuItem>
            </Select>
          </FormControl>
        ) : null;
      },
    },
  ];

  return (
    <DataGrid
      rows={sessionStore.selected.Speakers.concat(results)}
      columns={columns}
      getRowId={(row) => row.Id}
      getRowHeight={() => 'auto'}
      pageSizeOptions={[5, 10]}
      initialState={{
        pagination: { paginationModel: { pageSize: 5 } },
        sorting: { sortModel: [{ field: 'FirstName', sort: 'asc' }] },
      }}
      autoHeight
    />
  );
};

export default observer(PersonResultsTable);
