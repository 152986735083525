import { makeAutoObservable } from 'mobx';
import { router } from '..';
import agent from '../api/agent';
import { User, UserForm } from '../utils/types/user';
import { store } from './store';

export default class UserStore {
  user: User | null = null;
  refreshTokenTimeout: any;
  roles: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  isLoggedIn = () => {
    return !!this.user;
  };

  login = async (creds: UserForm) => {
    const user = await agent.Account.login(creds);
    store.commonStore.setTokens(user.access_token, user.id_token, user.refresh_token);

    this.getRoles(user);
    this.startRefreshTokenTimer(user);

    router.navigate('/');
  };

  logout = () => {
    store.commonStore.setTokens(null, null, null);
    this.user = null;
    this.roles = [];
    this.stopRefreshTokenTimer();
    router.navigate('/login');
  };

  getUser = async () => {
    const user = await agent.Account.current();
    this.getRoles(user);
    store.commonStore.setTokens(user.access_token, user.id_token, user.refresh_token);
    this.startRefreshTokenTimer(user);
  };

  refreshToken = async () => {
    try {
      const user = await agent.Account.refreshToken();
      this.getRoles(user);
      store.commonStore.setTokens(user.access_token, user.id_token, user.refresh_token);
      this.startRefreshTokenTimer(user);
    } catch (err) {
      console.log(err);
    }
  };

  private getRoles = (user: User) => {
    const claims = JSON.parse(window.atob(user.access_token.split('.')[1]));
    const roles = claims['roles'] as string[];

    this.roles = roles;
  };

  private startRefreshTokenTimer = (user: User) => {
    const expires = new Date(user.expires_in);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
  };

  private stopRefreshTokenTimer = () => {
    clearTimeout(this.refreshTokenTimeout);
  };
}
