import { OutlinedInputProps } from '@mui/material/OutlinedInput';

export interface InputPropTypes {
  name: string;
  label: string;
  props?: Partial<OutlinedInputProps>;
}

export interface TypeMix {
  Name: string;
  TypeId: number | null;
  ProgramTypeId: number | null;
}

export const Types: TypeMix[] = [
  {
    Name: 'Workshop',
    ProgramTypeId: 6,
    TypeId: 11,
  },
  {
    Name: 'Registration Product',
    ProgramTypeId: 10,
    TypeId: 14,
  },
  {
    Name: 'Event',
    ProgramTypeId: 13,
    TypeId: 13,
  },
  {
    Name: 'Industry Seminar',
    ProgramTypeId: 17,
    TypeId: 22,
  },
  {
    Name: 'Hands-on Lab',
    ProgramTypeId: 1,
    TypeId: 10,
  },
  {
    Name: 'Entertainment',
    ProgramTypeId: 20,
    TypeId: null,
  },
  {
    Name: 'Campfire',
    ProgramTypeId: 26,
    TypeId: null,
  },
  {
    Name: 'Learning Lounge',
    ProgramTypeId: 12,
    TypeId: null,
  },
  {
    Name: 'Special Presentation',
    ProgramTypeId: 2,
    TypeId: null,
  },
  {
    Name: 'Symposium',
    ProgramTypeId: 3,
    TypeId: null,
  },
  {
    Name: 'Lecture',
    ProgramTypeId: 4,
    TypeId: null,
  },
  {
    Name: 'Exhibitor Meeting Badge Products',
    ProgramTypeId: null,
    TypeId: 15,
  },
];
