import dayjs from 'dayjs';
import { StatusIds } from '../enums/status';
import { SessionViewExModel } from '../types/session';

export const defaultSession: SessionViewExModel = {
  Code: '',
  Category: {
    Id: 179,
    Name: '',
    ParentId: 190,
  },
  Credits: [
    {
      MeetingId: 0,
      Category: {
        Name: 'Continuing Education Units',
        Status: 'Active',
        ParentCategory: null,
        Id: 1,
      },
      EducationUnits: 0,
      Id: 0,
      IsMedical: false,
    },
  ],
  EndDate: dayjs(),
  ExternalNotes: null,
  InternalNotes: null,
  Keywords: null,
  LearningObjectives: [],
  MeetingProgramCategoryId: 183,
  MeetingProgramCategoryName: 'Not Used',
  MeetingRoom: null,
  Name: '',
  ProgramLevel: null,
  ProgramType: null,
  Price: [],
  ProgramArea: {
    Id: 8,
    Name: 'N/A',
  },
  ParentProductId: 28771,
  Speakers: [],
  Sponsors: [],
  StartDate: dayjs(),
  Status: {
    Id: StatusIds.Planning,
    Name: 'Building',
  },
  Type: null,
  TargetAudience: [],
  Venue: null,
};
