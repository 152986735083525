import { Person, Speaker } from './types/person';

export const mapSpeakerToPerson = (s: Speaker): Person => {
  return {
    Id: s.Id,
    FirstName: s.FirstName,
    LastName: s.LastName,
  };
};

export const mapPersonToSpeaker = (p: Person, type: string): Speaker => {
  return {
    Id: p.Id,
    FirstName: p.FirstName,
    LastName: p.LastName,
    Type: type,
  };
};
