import { useState, useMemo, useEffect } from 'react';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import PersonResultsTable from 'components/Session/Conference/SessionForm/Person/ResultsTable';
import agent from 'api/agent';
import { useStore } from 'stores/store';
import { Person } from 'utils/types/person';
import { mapPersonToSpeaker } from 'utils/person';

const Persons = () => {
  const { sessionStore } = useStore();
  const [people, setPeople] = useState<Person[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [delayedSearchValue, setDelayedSearchValue] = useState('');

  const getUserSearch = useMemo(
    () => (delayedSearchValue === '' ? undefined : () => agent.Persons.search(delayedSearchValue.trim(), 28771)),
    [delayedSearchValue]
  );

  useEffect(() => {
    if (!getUserSearch) return;
    getUserSearch().then((resp) => {
      setPeople(resp.filter((x) => !sessionStore.selected.Speakers.map((y) => y.Id).includes(x.Id)));
    });
  }, [getUserSearch, sessionStore.selected.Speakers]);

  useEffect(() => {
    if (searchValue.length >= 3) {
      const delayDebounceFn = setTimeout(async () => {
        setDelayedSearchValue(searchValue.trim());
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    } else if (searchValue.length === 0) {
      setPeople([]);
    }
  }, [searchValue]);

  return (
    <Box sx={{ width: '100%', marginTop: 2 }}>
      <TextField
        fullWidth
        placeholder="Start typing to search..."
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.currentTarget.value);
        }}
      />
      <PersonResultsTable
        results={people.map((x) => mapPersonToSpeaker(x, ''))}
        setResults={(s) => {
          setPeople(
            s.sort((x, y) => {
              if (x.FirstName > y.FirstName) {
                return 1;
              }
              return 0;
            })
          );
        }}
      />
    </Box>
  );
};

export default Persons;
