import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontSize: 16,
    fontFamily: ['Poppins', 'Montserrat', '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
    body1: {
      fontSize: 16,
    },
    button: {
      fontFamily: ['Effra', 'Poppins', 'Montserrat', '-apple-system', 'BlinkMacSystemFont', 'sans-serif'].join(','),
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1100,
      xl: 1920,
    },
  },
  components: {
    // NOTE: This is a workaround to fix an issue with Safari 14
    // displaying the button color wrong after it changed to enabled state.
    //
    // See: https://github.com/mui-org/material-ui/issues/26251
    MuiButton: {
      styleOverrides: {
        root: {
          transition: 'color .01s',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#40C0C0',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#00556F',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#54C0E8',
    },
    success: {
      main: '#90D5AC',
    },
  },
});
