import dayjs from 'dayjs';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import { SessionFilters } from 'utils/types/sessionFilters';
import { router } from '..';
import agent from '../api/agent';
import { defaultSession } from '../utils/defaults/session';
import { StatusIds } from '../utils/enums/status';
import { mapPersonToSpeaker } from '../utils/person';
import { LearningObjectives } from '../utils/types/entity';
import { Person } from '../utils/types/person';
import {
  MeetingSearchViewModel,
  SessionListModel,
  SessionSearchRequest,
  SessionViewExModel,
} from '../utils/types/session';
import { store } from './store';

export default class SessionStore {
  display: SessionListModel[] = [];
  request: MeetingSearchViewModel | null = null;
  search = new Search();
  accordion = new Accordion();
  isModalOpen = false;
  selected: SessionViewExModel = defaultSession;
  currentStatusId: StatusIds | undefined;

  sessionsLoading: boolean = false;
  singleLoading: boolean = false;

  private sessions: SessionListModel[] = [];

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.search.pattern,
      () => {
        this.accordion.setExpanded(this.search.pattern);
        this.searchForSessions();
      }
    );

    reaction(
      () => this.search.searchRequest,
      (x) => {
        this.get(x);
      }
    );

    reaction(
      () => this.currentStatusId,
      () => {
        this.filterByStatus();
      }
    );
  }

  toggleModal = (isOpen: boolean): void => {
    this.isModalOpen = isOpen;
  };

  all = (): SessionListModel[] => {
    return this.sessions;
  };

  new = (): void => {
    this.selected = defaultSession;
  };

  listForDate = (_: Date): SessionListModel[] => {
    return this.display;
  };

  save = async (session: SessionViewExModel): Promise<void> => {
    try {
      if (!this.valid) {
        throw new Error('Not a valid session');
      }
      this.edit({
        ...session,
        LearningObjectives: this.selected.LearningObjectives.filter((x) => x.Objective !== ''),
      });
      await agent.Sessions.save(this.selected);

      runInAction(() => {
        this.new();
        this.reset();
      });

      router.navigate('/');
    } catch (e) {
      console.error(e);
    } finally {
      runInAction(() => {
        // TODO:
        // stop loading
      });
    }
  };

  delete = async (session: SessionViewExModel): Promise<void> => {
    try {
      // TODO: call endpoint to delete the session

      this.sessions = this.sessions.filter((sesh) => session.Code !== sesh.Code);
      this.reset();
    } catch (e) {
      // TODO: if anything goes wrong console log the error
      // and display a toast
    }
  };

  duplicateSelectedSession = async (): Promise<void> => {
    this.selected.ProductId = 0;
    this.selected.MeetingId = 0;
    this.selected.MeetingRoom = null;
    this.selected.Status.Id = StatusIds.Planning;

    try {
      await this.save(this.selected);
    } catch {
      toast.error('could not duplicate session');
    }
  };

  get = async (request: SessionSearchRequest): Promise<void> => {
    try {
      this.sessionsLoading = true;
      const sessions = await agent.Sessions.list(request);

      runInAction(() => {
        this.sessions = sessions.Meetings.map((x) => {
          return {
            ...x,
            StartDate: new Date(x.StartDate),
            EndDate: new Date(x.EndDate),
          };
        });

        this.request = sessions;

        this.reset();
      });

      this.filterByStatus();
    } catch (e) {
      console.error(e);
    } finally {
      runInAction(() => {
        this.sessionsLoading = false;
      });
    }
  };

  getSingle = async (productId: number): Promise<void> => {
    try {
      this.singleLoading = true;
      const selected = await agent.Sessions.getSingle(productId);
      runInAction(() => {
        this.select({ ...selected, StartDate: dayjs(selected.StartDate), EndDate: dayjs(selected.EndDate) });
      });
    } finally {
      runInAction(() => {
        this.singleLoading = false;
      });
    }
  };

  private select = (session: SessionViewExModel): void => {
    if (!session) {
      this.new();
      return;
    }
    //store.annualConferenceStore.selectDate(session.StartDate);
    store.timeStore.setStartTime(session.StartDate);
    store.timeStore.setEndTime(session.EndDate);
    this.selected = session;
  };

  edit = (session: SessionViewExModel): void => {
    this.selected = session;
  };

  addPerson = (p: Person[], type: string): void => {
    if (!this.selected.Speakers) {
      this.selected.Speakers = [];
    }

    this.selected.Speakers = this.selected.Speakers.filter((x) => p.map((y) => y.Id).includes(x.Id));

    p.forEach((x) => this.selected.Speakers.push(mapPersonToSpeaker(x, type)));
  };

  addLearningObjective = (): void => {
    if (this.selected?.LearningObjectives && this.selected.LearningObjectives.length >= 10) {
      // TODO: display a toast saying too much learning objectives
      throw new Error('Too many learning objectives');
    }

    if (!this.selected.LearningObjectives) {
      this.selected.LearningObjectives = [];
    }

    this.selected.LearningObjectives?.push({
      Id: 0,
      MeetingID: this.selected.MeetingId as number,
      Objective: '',
      Sequence: this.selected.LearningObjectives.length + 1,
    });
  };

  modifyLearningObjective = (objective: LearningObjectives): void => {
    this.selected!.LearningObjectives!.find((ob) => ob.Id === objective.Id)!.Objective = objective.Objective;
    // this.sessions.find(
    // (session) => session.Code === objective.sessionId
    // )!.learningObjectives = this.selected?.learningObjectives;
  };

  deleteLearningObjective = (objective: LearningObjectives): void => {
    this.selected.LearningObjectives!.filter((lo) => lo.Id !== objective.Id);
  };

  updateSessionStatus = async (status: StatusIds): Promise<void> => {
    if (!this.selected.ProductId) return;
    await agent.Sessions.updateStatus(this.selected.ProductId, status);
    this.edit({
      ...this.selected,
      Status: {
        ...this.selected.Status,
        Id: status,
      },
    });
  };

  changeStatus = (status?: StatusIds): void => {
    this.currentStatusId = status;
  };

  private filterByStatus = (): void => {
    if (!this.currentStatusId) {
      this.display = this.sessions;
      return;
    }
    this.accordion.panel = true;
    this.display = this.sessions.filter((x) => x.StatusId === this.currentStatusId);
  };

  private valid = (): boolean => {
    if (this.selected.Name === '' || this.selected.Code === '') {
      return false;
    }
    return true;
  };

  private reset = (): void => {
    this.display = this.sessions;
    this.search.setPattern('');
    this.new();
  };

  private searchForSessions = (): void => {
    // TODO: search for speakers
    this.display = this.sessions.filter(
      (session) => session.Code.toLowerCase().includes(this.search.pattern.toLowerCase()) // ||
      // session.Name.toLowerCase().includes(
      // this.search.pattern.toLowerCase()
      // )
    );
  };
}

class Search {
  pattern: string = '';
  searchRequest: SessionSearchRequest = {};
  filters: SessionFilters | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchFilters(): Promise<void> {
    const filters = await agent.Sessions.fetchFilters();
    runInAction(() => {
      this.filters = filters;
    });
  }

  setPattern(pattern: string): void {
    this.pattern = pattern.toLowerCase();
    this.searchRequest.SearchTerm = pattern.toLowerCase();
  }

  setSearchRequest(searchRequest: SessionSearchRequest): void {
    this.searchRequest = searchRequest;
  }
}

class Accordion {
  panel: string | boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setPanel = (panel: string, isExpanded: boolean): void => {
    this.panel = isExpanded ? panel : false;
  };

  setExpanded = (pattern: string): void => {
    if (pattern === '') {
      this.panel = false;
      return;
    }

    this.panel = true;
  };
}
