import { Box, Grid, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useStore } from 'stores/store';
import { SessionSearchRequest } from 'utils/types/session';
import ProductFilter from 'components/Session/Shared/ProductFilter';
import styles from './AllSearch.module.scss';
import StaticSelectOption from 'utils/types/staticSelectOptions';
import { StatusIdNames } from 'utils/enums/status';
import ProductSearch from '../Shared/ProductSearch/ProductSearch';

const AllSearch: React.FunctionComponent = () => {
  const { sessionStore } = useStore();
  const [searchValue, setSearchValue] = useState<SessionSearchRequest | null>(null);
  const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
  const [programAreaFilter, setProgramAreaFilter] = useState<string[]>([]);
  const [audienceFilter, setAudienceFilter] = useState<string[]>([]);
  const [programTypeFilter, setProgramTypeFilter] = useState<string[]>([]);
  const [productCategoryFilter, setProductCategoryFilter] = useState<string[]>([]);
  const [topLevelProductFilter, setTopLevelProductFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);

  function handleDelayedSearchChange(key: keyof SessionSearchRequest, searchRequest: SessionSearchRequest): void {
    setSearchValue({ ...searchValue, ...{ [key]: searchRequest[key] } });
  }

  function handleSearchChange(key: keyof SessionSearchRequest, searchRequest: SessionSearchRequest): void {
    sessionStore.search.setSearchRequest({
      ...searchValue,
      ...{ [key]: searchRequest[key] },
    });
  }

  const availableStatus: StaticSelectOption[] = useMemo(() => {
    return StatusIdNames.map((sin) => ({ value: sin.id.toString(), text: sin.text }));
  }, []);

  const availableCategories: StaticSelectOption[] = useMemo(() => {
    return (
      sessionStore.search.filters?.MeetingCategories.map((c) => ({ value: c.Id.toString(), text: c.Name })).sort(
        (x, y) => x.text.localeCompare(y.text)
      ) || ([] as StaticSelectOption[])
    );
  }, [sessionStore.search.filters?.MeetingCategories]);

  const availableTargetAudience: StaticSelectOption[] = useMemo(() => {
    return (
      sessionStore.search.filters?.TargetAudiences.map((c) => ({ value: c, text: c })).sort((x, y) =>
        x.text.localeCompare(y.text)
      ) || ([] as StaticSelectOption[])
    );
  }, [sessionStore.search.filters?.TargetAudiences]);

  const availableProgramType: StaticSelectOption[] = useMemo(() => {
    return (
      sessionStore.search.filters?.ProgramTypes.map((c) => ({ value: c.Id!.toString(), text: c.Name })).sort((x, y) =>
        x.text.localeCompare(y.text)
      ) || ([] as StaticSelectOption[])
    );
  }, [sessionStore.search.filters?.ProgramTypes]);

  const availableAreaType: StaticSelectOption[] = useMemo(() => {
    return (
      sessionStore.search.filters?.ProgramAreas.map((c) => ({ value: c.Id.toString(), text: c.Name })).sort((x, y) =>
        x.text.localeCompare(y.text)
      ) || ([] as StaticSelectOption[])
    );
  }, [sessionStore.search.filters?.ProgramAreas]);

  const availableProductCategoryFilters: StaticSelectOption[] = useMemo(() => {
    return (
      sessionStore.search.filters?.TopLevelCategories.map((c) => ({ value: c.Id.toString(), text: c.Name })).sort(
        (x, y) => x.text.localeCompare(y.text)
      ) || ([] as StaticSelectOption[])
    );
  }, [sessionStore.search.filters?.TopLevelCategories]);

  const availableTopLevelProducts: StaticSelectOption[] = useMemo(() => {
    return (
      sessionStore.search.filters?.TopLevelProducts.map((c) => ({ value: c.ProductId.toString(), text: c.Name })).sort(
        (x, y) => x.text.localeCompare(y.text)
      ) || ([] as StaticSelectOption[])
    );
  }, [sessionStore.search.filters?.TopLevelProducts]);

  useEffect(() => {
    setSearchValue((x) => ({
      ...x,
      ParentProductId: topLevelProductFilter.map((x) => parseInt(x)),
      ProductCategoryId: productCategoryFilter.map((x) => parseInt(x)),
      MeetingCategoryIds: categoryFilter.map((x) => parseInt(x)),
      TargetAudienceNames: audienceFilter,
      TypeIds: programTypeFilter.map((x) => parseInt(x)),
      AreaIds: programAreaFilter.map((x) => parseInt(x)),
      Page: 1,
      StatusIds: statusFilter.map((x) => parseInt(x)),
    }));
  }, [
    audienceFilter,
    categoryFilter,
    productCategoryFilter,
    programAreaFilter,
    programTypeFilter,
    statusFilter,
    topLevelProductFilter,
  ]);

  useEffect(() => {
    sessionStore.search.fetchFilters();
  }, [sessionStore]);

  useEffect(() => {
    if (searchValue !== null) {
      const delayDebounceFn = setTimeout(async () => {
        sessionStore.search.setSearchRequest(searchValue);
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchValue, sessionStore.search]);

  return (
    <Box className={styles.container}>
      <TextField
        fullWidth
        id="outlined-basic"
        label="Search Sessions"
        variant="outlined"
        value={searchValue?.SearchTerm || ''}
        onChange={(e) => {
          handleDelayedSearchChange('SearchTerm', { SearchTerm: e.currentTarget.value });
        }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={3}>
          <ProductFilter
            setFilterValue={setProductCategoryFilter}
            filterValue={productCategoryFilter}
            id={'product-category-filter'}
            label={'Product Categories'}
            options={availableProductCategoryFilters}
          ></ProductFilter>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ProductFilter
            setFilterValue={setTopLevelProductFilter}
            filterValue={topLevelProductFilter}
            id={'parent-product-filter'}
            label={'Parent Products'}
            options={availableTopLevelProducts}
          ></ProductFilter>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ProductFilter
            setFilterValue={setCategoryFilter}
            filterValue={categoryFilter}
            id={'category-filter'}
            label={'Categories'}
            options={availableCategories}
          ></ProductFilter>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ProductFilter
            setFilterValue={setAudienceFilter}
            filterValue={audienceFilter}
            id={'audience-type-filter'}
            label={'Audience'}
            options={availableTargetAudience}
          ></ProductFilter>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ProductFilter
            setFilterValue={setProgramTypeFilter}
            filterValue={programTypeFilter}
            id={'program-type-filter'}
            label={'Program Type'}
            options={availableProgramType}
          ></ProductFilter>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ProductFilter
            setFilterValue={setProgramAreaFilter}
            filterValue={programAreaFilter}
            id={'area-type-filter'}
            label={'Track'}
            options={availableAreaType}
          ></ProductFilter>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ProductFilter
            setFilterValue={setStatusFilter}
            filterValue={statusFilter}
            id={'status-filter'}
            label={'Status'}
            options={availableStatus}
          ></ProductFilter>
        </Grid>
      </Grid>
      <ProductSearch
        page={page}
        onPageChange={(v) => {
          setPage(v);
          handleSearchChange('Page', { Page: v });
        }}
        buttonLink="/edit"
        buttonText="Edit"
      />
    </Box>
  );
};

export default observer(AllSearch);
