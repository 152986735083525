import { Box, Button, Card, CardActions, CardContent, CardHeader, Pagination, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useStore } from 'stores/store';
import { getFormattedDateCombination } from 'utils/date';
import styles from './ProductSearch.module.scss';

interface ProductSearchProps {
  page: number;
  buttonText?: string;
  buttonLink?: string;
  onPageChange: (page: number) => void;
}

const ProductSearch: React.FunctionComponent<ProductSearchProps> = ({ page, buttonText, buttonLink, onPageChange }) => {
  const { sessionStore } = useStore();
  return (
    <Box className={styles.container}>
      <Box className={styles.sessionContainer}>
        {sessionStore.display.map((x) => (
          <Card key={x.Id} className={styles.sessionCard}>
            <CardHeader title={`${x.Code} - ${x.Title}`} />
            <CardContent>
              <Typography>Date: {getFormattedDateCombination(x.StartDate, x.EndDate)}</Typography>
              {x.Prices.length > 0 && (
                <Typography>
                  Price{x.Prices.length > 1 && 's'}: {x.Prices.map((x) => `$${x.Price}`).join(', ')}
                </Typography>
              )}
            </CardContent>
            <CardActions className={styles.cardActions}>
              <Link className={styles.link} to={`${buttonLink}/${x.Id}`}>
                <Button variant="contained">{buttonText}</Button>
              </Link>
            </CardActions>
          </Card>
        ))}
      </Box>
      <Pagination
        count={sessionStore.request?.MaxPage}
        page={page}
        onChange={(_, v) => {
          onPageChange(v);
        }}
      />
    </Box>
  );
};

export default observer(ProductSearch);
