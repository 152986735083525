import React, { useMemo } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextFieldProps,
} from '@mui/material';

import StaticSelectOption from 'utils/types/staticSelectOptions';

interface ProductFilterProps extends Pick<TextFieldProps, 'size'> {
  setFilterValue: (value: string[]) => void;
  filterValue: string[];
  id: string;
  label: string;
  options: StaticSelectOption[];
}

const ProductFilter: React.FunctionComponent<ProductFilterProps> = ({
  setFilterValue,
  filterValue,
  id,
  label,
  options,
  size = 'small',
}) => {
  const menuOptions = useMemo(() => {
    return options.map(({ text, value }: StaticSelectOption) => (
      <MenuItem value={value} key={`state-${value}`}>
        <Checkbox checked={filterValue.indexOf(value) > -1} />
        <ListItemText primary={text} />
      </MenuItem>
    ));
  }, [filterValue, options]);

  const handleChange = (event: SelectChangeEvent<typeof filterValue>) => {
    const {
      target: { value },
    } = event;
    setFilterValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth size={size}>
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
        <Select
          labelId={`${id}-label`}
          id={id}
          multiple
          value={filterValue}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => {
            return (selected as string[])?.map((value) => options.find((o) => o.value === value)?.text).join(', ');
          }}
        >
          {menuOptions}
        </Select>
      </FormControl>
    </Box>
  );
};
export default ProductFilter;
