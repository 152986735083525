import axios, { AxiosError, AxiosResponse } from 'axios';
import { msalInstance } from 'index';
import { toast } from 'react-toastify';
import { store } from 'stores/store';
import { StatusIds } from 'utils/enums/status';
import { AnnualConference } from 'utils/types/annualConference';
import { SessionDropdowns } from 'utils/types/entity';
import { Meeting } from 'utils/types/meeting';
import { Person } from 'utils/types/person';
import { Resource } from 'utils/types/resources';
import { MeetingSearchViewModel, SessionSearchRequest, SessionViewExModel } from 'utils/types/session';
import { SessionFilters } from 'utils/types/sessionFilters';
import { User, UserForm } from 'utils/types/user';

const aptify = axios.create({
  baseURL: process.env.REACT_APP_SERVICES_API_URL,
});

const account = axios.create({
  baseURL: process.env.REACT_APP_ACCOUNT_API_URL,
});

const msal = axios.create({
  baseURL: process.env.REACT_APP_SERVICES_API_URL,
});

msal.interceptors.request.use(async (config) => {
  try {
    const resp = await msalInstance.acquireTokenSilent({
      scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/login`],
      account: msalInstance.getAllAccounts()[0],
    });
    config.headers.set('X-Aura-Authorization', resp.accessToken);
  } catch {
    try {
      const resp = await msalInstance.acquireTokenPopup({
        scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/login`],
        account: msalInstance.getAllAccounts()[0],
      });
      config.headers.set('X-Aura-Authorization', resp.accessToken);
    } catch {
      toast.error('Could not login to Microsoft');
    }
  }

  return config;
});

aptify.interceptors.request.use((config) => {
  const token = store.commonStore.accessToken;
  config.headers.set('X-Aura-Authorization', 't3stk3y');

  if (token && config.headers) {
    config.headers.set('X-Aura-Authorization', token);
  }

  return config;
});

account.interceptors.request.use((config) => {
  const token = store.commonStore.accessToken;
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

aptify.interceptors.response.use(
  async (resp) => {
    return resp;
  },
  (err: AxiosError) => {
    const { status, headers } = err.response!;

    switch (status) {
      case 400:
        toast.error('Not Found');
        break;
      case 401:
        if (headers['www-authenticate']?.startsWith('Bearer error="invalid_token"')) {
          store.userStore.logout();
          toast.error('Session expired - please login again');
          break;
        }
        toast.error('Unauthorized');
        break;
      case 404:
        toast.error('Not Found');
        break;
      case 500:
        toast.error('Server Error');
        break;
    }

    Promise.reject(err);
  }
);

account.interceptors.response.use(
  async (resp) => {
    return resp;
  },
  (err: AxiosError) => {
    const { status, headers } = err.response!;

    switch (status) {
      case 400:
        toast.error('Not Found');
        break;
      case 401:
        if (headers['www-authenticate']?.startsWith('Bearer error="invalid_token"')) {
          store.userStore.logout();
          toast.error('Session expired - please login again');
          break;
        }
        toast.error('Unauthorized');
        break;
      case 404:
        toast.error('Not Found');
        break;
      case 500:
        toast.error('Server Error');
        break;
    }

    Promise.reject(err);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const aptifyRequests = {
  get: <T>(url: string, params?: object) => aptify.get<T>(url, { params }).then(responseBody),
  post: <T>(url: string, body: object, params?: object) =>
    aptify.post<T>(url, body, { params: params }).then(responseBody),
  put: <T>(url: string, body: object, params?: object) =>
    aptify.put<T>(url, body, { params: params }).then(responseBody),
  del: <T>(url: string, body: object) => aptify.delete<T>(url, { data: body }).then(responseBody),
};

const accountRequests = {
  get: <T>(url: string) => account.get<T>(url).then(responseBody),
  post: <T>(url: string, body: object, params?: object) =>
    account.post<T>(url, body, { params: params }).then(responseBody),
  put: <T>(url: string, body: object, params?: object) =>
    account.put<T>(url, body, { params: params }).then(responseBody),
  del: <T>(url: string, body: object) => account.delete<T>(url, { data: body }).then(responseBody),
};

const msalRequests = {
  get: <T>(url: string, params?: object) => msal.get<T>(url, { params }).then(responseBody),
  post: <T>(url: string, body: object, params?: object) =>
    msal.post<T>(url, body, { params: params }).then(responseBody),
  put: <T>(url: string, body: object, params?: object) => msal.put<T>(url, body, { params: params }).then(responseBody),
  del: <T>(url: string, body: object) => msal.delete<T>(url, { data: body }).then(responseBody),
};

const Sessions = {
  fetchFilters: () => msalRequests.get<SessionFilters>(`/Viticus/Internal/SessionFilters`),
  list: (request: SessionSearchRequest) =>
    msalRequests.get<MeetingSearchViewModel>(`/Viticus/Internal/Session`, { ...request, ShowAll: true }),
  save: (session: SessionViewExModel) => msalRequests.post('/Viticus/Internal/CreateSession', session),
  getSingle: (id: number) =>
    msalRequests.get<SessionViewExModel>(`/Viticus/Internal/SessionDetails`, { productId: id }),
  updateStatus: (productId: number, statusId: StatusIds) =>
    msalRequests.post(
      '/MeetingEx/UpdateSessionStatus',
      {},
      {
        productId,
        statusId,
      }
    ),
  accountingDecision: (productId: number, isApproved: boolean) =>
    msalRequests.post(
      `/Viticus/Internal/AccountingSessionDecision`,
      {},
      {
        productId,
        isApproved,
      }
    ),
  headOfEduDecision: (productId: number, isApproved: boolean) =>
    msalRequests.post(
      `/Viticus/Internal/HeadOfEducationSessionDecision`,
      {},
      {
        productId,
        isApproved,
      }
    ),
  submitForApproval: (productId: number) =>
    msalRequests.post(
      `/Viticus/Internal/SubmitSessionForApproval`,
      {},
      {
        productId,
      }
    ),
};

const Account = {
  current: () => accountRequests.get<User>('/account'),
  login: (user: UserForm) => accountRequests.post<User>('/account/login', user),
  refreshToken: () => accountRequests.post<User>('/account/refreshToken', {}),
};

const Persons = {
  search: (name: string, yearId: number) =>
    aptifyRequests.get<Person[]>(`/PersonEx/UserSearch?name=${name}&productId=${yearId}`),
};

const Entity = {
  dropdowns: () => msalRequests.get<SessionDropdowns>('/Viticus/Internal/GetSessionDropdowns'),
};

const AnnualConferenceNames = {
  list: () => aptifyRequests.get<AnnualConference[]>('/MeetingEx/GetAnnualConferenceNames'),
  getSingle: (id: number) => aptifyRequests.get<Meeting>(`/MeetingEx/GetSingleByProductId/${id}`),
};

const Inventory = {
  list: () => msalRequests.get<Resource[]>('/Viticus/Internal/GetResources'),
  save: (resource: Resource) => msalRequests.post<Resource>('/Viticus/Internal/SaveResource', resource),
  getSingle: (id: number) => msalRequests.get<Resource>(`/Viticus/Internal/GetResource`, { resourceId: id }),
};

const agent = {
  Account,
  AnnualConferenceNames,
  Entity,
  Persons,
  Sessions,
  Inventory,
};

export default agent;
